.blocker {
    padding: 0;
    z-index: 1000;
    background: #F5F5F5;
}

.modal {
    width: 100%;
    position: static;
    max-width: 780px;
    box-shadow: none;
    padding: 50px;
    border-radius: 0;
    background: none;
}

.modal a.close-modal {
    width: 64px;
    height: 64px;
    top: 55px;
    right: 45px;
    background: none;

    @include linksHover();

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 75%;
        height: 5px;
        background: $c-main;
        transform: translate(-50%, -50%) rotate(45deg);
        top: 50%;
        left: 50%;
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.modal__title {
    font-size: 50px;
    line-height: 65px;
    margin-bottom: 40px;
    font-weight: bold;
    color: $c-titles;
    text-align: center;
}

.modal__desc {
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    text-align: center;
    font-family: $f-Roboto;
    margin-bottom: 100px;
}

.modal__form {
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
}

.modal__subinfo {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    font-family: $f-Roboto;
    max-width: 455px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 991px) {
    .modal a.close-modal {
        top: 15px;
        right: 15px;
    }
}

@media (max-width: 767px){
    .modal {
        padding: 50px 20px;
    }

    .modal__title {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 20px;
    }

    .modal__desc {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 50px;
    }

    .modal a.close-modal {
        width: 35px;
        height: 35px;
        top: 15px;
        right: 15px;

        &:before,
        &:after {
            height: 3px;
        }
    }
}