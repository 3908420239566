.about {
    padding: 120px 0 125px;

    &_pt-165 {
        padding-top: 165px;
    }
}

.about__head {
    display: flex;
    margin-bottom: 100px;
}

.about__title {
    font-size: 50px;
    line-height: 65px;
    font-weight: bold;
    color: $c-titles;
    margin-right: 105px;
    flex: 0 0 auto;
    width: 482px;
    max-width: 100%;
}

.about__desc {
    padding-left: 40px;
    width: 1025px;
    max-width: 100%;
    border-left: 4px solid #DF1616;
    font-size: 18px;
    font-family: $f-Roboto;
    line-height: 29px;
    color: #333333;
    align-self: flex-start;
}

.about__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;
}

.about__item {
    flex: 0 0 auto;
    padding: 0 15px;
    width: 33.33333333333333%;
    margin-bottom: 30px;
}

.about__card {
    display: block;
    height: 330px;
    position: relative;
    overflow: hidden;
    transition: all .3s;

    &:hover {
        @media (min-width: 1100px){
            .about__card-img-container { 
                &:before {
                    opacity: 0;
                    height: 100%;
                }

                &:after {
                    opacity: 0.8;
                    height: 100%;
                }
            }

            .about__card-bot-info {
                opacity: 0;
                transform: translateY(-200%);
            }

            .about__card-sub-info {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @media (max-width: 1099px){
            opacity: .7;
        }
    }    
}

.about__card-img-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 35px 35px 45px;
    
    &:before {
        position: absolute;
        display: block;
        content: "";
        transition: all .3s;
        left: 0;
        bottom: 0;
        right: 0;
        height: 157px;
        background: linear-gradient(0deg, rgba(2, 6, 27, 0.8) -25.93%, rgba(3, 7, 35, 0) 71.58%);
        z-index: 1;
    }

    &:after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        opacity: 0;
        transition: opacity .3s;
        background: rgb(0, 8, 61);
    }
}

.about__card-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.about__card-sub-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .3s;
    z-index: 1;
    padding: 50px 35px 35px 45px;
    transform: translateY(50%);
}

.about__card-bot-info {
    position: relative;
    z-index: 1;
    margin-top: auto;
    padding: 0 0 0 35px;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    font-weight: bold;
    transition: all .2s;
    transform: translateY(0);

    &:before {
        position: absolute;
        display: block;
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #fff;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -1px;
    }

    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 5px;
        height: 5px;
        border: 1px solid #fff;
        border-top: none;
        border-right: none;
        transform: translateY(-50%) rotate(-135deg);
        top: 50%;
        left: 5px;
        margin-top: -1px;
    }
}

.about__card-sub-info-title {
    padding-left: 34px;
    border-left: 4px solid #DF1616;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 28px;
    color: #fff;
}

.about__card-sub-info-desc {
    font-family: $f-Roboto;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
}

.about__card-sub-info-btn {
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 46px;
    height: 46px;
    right: 35px;
    bottom: 24px;

    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        border-top: none;
        border-right: none;
        transform: translate(-50%, -50%) rotate(-135deg);
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -2px;
    }
}

.about__more-btn-wrap {
    margin-bottom: 60px;
}

.about__bot-btns-list {
    display: flex;
    margin: 0 -5px;
}

.about__bot-btns-item {
    flex: 0 0 auto;
    padding: 0 5px;
    width: 33.3333333333333333%;
}

@media (max-width: 1279px) {
    .about {
        padding-top: 90px;
        padding-bottom: 125px;

        &_pt-165 {
            padding-top: 115px;
        }
    }

    .about__head {
        flex-direction: column;
        margin-bottom: 60px;
    }

    .about__title {
        margin-bottom: 20px;
    }

    .about__desc {
        max-width: 792px;
        width: auto;
    }

    .about__list {
        margin: 0 -10px -20px;
    }

    .about__item {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 20px;
    }

    .about__bot-btns-list {
        margin: 0 -4px -4px;
        flex-wrap: wrap;
    }

    .about__bot-btns-item {
        padding: 0 4px;
        margin-bottom: 8px;
        flex: 1 0 auto;
        width: 50%;
    }

    .about__card-img-container {
        padding: 0 15px 35px 30px;
    }

    .about__card-sub-info {
        padding: 30px 15px 35px 30px;
    }

    .about__card-sub-info-btn {
        right: 20px;
        bottom: 20px;
    }
}

@media (max-width: 991px) {
    .about__bot-btns-item {
        width: 100%;
    }

    .about__title {
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .about {
        padding-top: 60px;
        padding-bottom: 60px;

        &_pt-165 {
            padding-top: 115px;
        }
    }
    
    .about__bot-btns-list {
        margin-bottom: 0;
    }

    .about__bot-btns-item {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .about__title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 30px;
    }

    .about__desc {
        max-width: 100%;
        padding-left: 20px;
        font-size: 16px;
        line-height: 22px;
    }

    .about__list {
        margin-bottom: 0;
    }

    .about__item {
        width: 100%;
        margin-bottom: 10px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    .about__card {
        height: 235px;
    }

    .about__card-sub-info {
        display: none;
    }

    .about__card-img-container {
        padding: 15px;
    }

    .about__card-bot-info {
        padding-left: 0;
        font-size: 16px;
        line-height: 19px;

        &:before {
            top: -22px;
        }

        &:after {
            top: -25px;
            transform: rotate(-135deg);
        }
    }

    .about__more-btn-wrap {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}