.modal-design.modal {
    max-width: 100%;
    padding: 0;
    background: #fff;

    a.close-modal {
        top: 30px;
        right: 25px;

        &:before,
        &:after {
            background: #D83333;
        }
    }
}

.modal-design__head {
    padding: 135px 160px 120px 125px;
}

.modal-design__tag {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: bold;
    color: #BDBDBD;
}

.modal-design__title {
    font-weight: bold;
    font-size: 50px;
    line-height: 65px;
    color: $c-titles;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.modal-design__desc {
    font-size: 16px;
    line-height: 24px;
    font-family: $f-Roboto;
    color: #333333;
}

.modal-design__img-container {
    position: relative;
}

.modal-design__img {
    width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 767px) {
    .modal-design.modal a.close-modal {
        top: 20px;
        right: 20px;
    }

    .modal-design__head {
        padding: 85px 20px 55px;
    }

    .modal-design__tag {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    
    .modal-design__title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 35px;
    }
}