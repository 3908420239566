.p-company__hero-info {
    display: flex;
    padding-bottom: 90px;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;

    &:after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        bottom: 0;
        right: 0;
        height: 65%;
        background: linear-gradient(to top, rgba(2, 6, 27, 0.8) -25.93%, rgba(3, 7, 35, 0) 71.58%);
        pointer-events: none;
        z-index: 1;
    }
}

.p-company__hero-info-item {
    position: relative;
    padding-left: 70px;
    padding-top: 95px;
    margin-right: 45px;

    &:last-child {
        margin-right: 0;
    }
}

.p-company__hero-info-item-num {
    font-style: italic;
    font-size: 106px;
    line-height: 170px;
    color: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0;
    left: 0;
}

.p-company__hero-info-item-title {
    position: relative;
    width: 295px;
    max-width: 100%;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    color: #fff;
    z-index: 1;
}

.p-company__hero {
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        height: 40%;
        background: linear-gradient(to bottom, rgba(2, 6, 27, 0.8) -25.93%, rgba(3, 7, 35, 0) 71.58%);
        pointer-events: none;
    }

    &:after {
        position: absolute;
        display: block;
        content: "";
        left: 0;
        bottom: 0;
        right: 0;
        height: 65%;
        background: linear-gradient(to top, #000000 6.79%, rgba(0, 0, 0, 0) 57.73%);
        pointer-events: none;
    }
}

.p-company__hero-img-c {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
}

.p-company__hero-img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}

@media (max-width: 1279px) {
    .p-company__hero {
        flex: 1;
    }

    .p-company__hero-info {
        flex: 1;
        height: auto;
        align-self: stretch;
    }

    .p-company__hero-info-item {
        padding-left: 40px;
        padding-top: 75px;
        margin-right: 15px;
    }

    .p-company__hero-info-item-num {
        font-size: 86px;
        line-height: 140px;
    }

    .p-company__hero-info-item-title {
        font-size: 26px;
        line-height: 34px;
        width: 235px;
    }
}

@media (max-width: 991px) {
    .p-company__hero-info {
        flex-wrap: wrap;
        align-content: flex-end;
    }

    .p-company__hero-info-item {
        margin-right: 0;
        width: 300px;
    }
}

@media (max-width: 767px) {
    .p-company__hero-info {
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        padding-top: 90px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        &:after {
            opacity: 0.4;
            background: black;
            height: 100%;
            z-index: 0;
        }
    }

    .p-company__hero-info-item {
        margin-bottom: 30px;
        width: auto;
        padding-left: 40px;
        padding-top: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .p-company__hero-info-item-title {
        width: 100%;
        font-size: 20px;
        line-height: 26px;
    }

    .p-company__hero-info-item-num {
        font-size: 56px;
        line-height: 56px;
        color: rgba(255, 255, 255, 0.9);
    }

    .p-company__hero-img {
        object-position: left center;
    }
}