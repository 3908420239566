.p-products__design-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -40px;
}

.p-products__design-item {
    position: relative;
    display: flex;
    margin-bottom: 25px;
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;

    &:hover {
        .p-products__design-item-img-container {
            &:before {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    &.active {
        .p-products__design-item-desc {
            &_low {
                display: none;
            }

            &_full {
                display: block;
            }
        }

        .p-products__design-item-img-container {
            &:before {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}

.p-products__design-item-l {
    position: relative;
    flex: 0 0 auto;
    width: 125px;
    margin-right: 30px;

    &:before {
        display: none;
        position: absolute;
        content: "";
        top: 100%;
        margin-top: 10px;
        left: 0;
        width: 30px;
        height: 2px;
        background: #DC0000;
    }
}

.p-products__design-item-r {
    position: relative;
    flex: 1;
    min-width: 0;
}

.p-products__design-item-tag {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: #BDBDBD;
    text-transform: uppercase;
    font-family: $f-Roboto;
    margin-bottom: 10px;
}

.p-products__design-item-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    font-family: $f-Roboto;
    font-weight: bold;
    color: #040C44;
    text-transform: uppercase;
}

.p-products__design-item-desc {
    font-size: 16px;
    line-height: 21px;
    font-family: $f-Roboto;
    color: #333333;

    &_low {
        display: block;
    }

    &_full {
        display: none;
    }
}

.p-products__design-item-img-container {
    padding-top: 100%;
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: -10px;
        bottom: 0;
        width: 10px;
        background: #DC0000;
        z-index: 1;
        transition: all .3s;
        transform: translateX(-100%);
        opacity: 0;
    }
}

.p-products__design-item-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: initial;
    object-fit: cover;
}

.p-products__design-list-space-fix {
    font-size: 0;
    opacity: 0;
    height: 0;
    text-indent: -9999px;
    display: block;
}


@media (max-width: 1599px) {
   
}

@media (max-width: 1279px) {
    .p-products__design-item {
        width: 33.33333333333333%;
        padding: 0 12px;
        flex-direction: column;
    }

    .p-products__design-item-img-container {
        padding-top: 75%;

        &:before {
            display: none;
        }
    }

    .p-products__design-item-l {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;

        &:before {
            display: block;
        }
    }

    .p-products__design-item-r {
        .p-products__design-item-tag,
        .p-products__design-item-desc,
        .p-products__design-item-desc.p-products__design-item-desc_full,
        .p-products__design-item-desc.p-products__design-item-desc_low {
            display: none;
        }

        .p-products__design-item-title {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 767px) {
    .p-products__design-item {
        width: 100%;
        margin-bottom: 30px;
    }

    .p-products__design-list {
        margin-bottom: -30px;
    }
}