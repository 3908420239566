.p-objects__choosen-filters-list-c {
    min-height: 19px;
    margin-bottom: 40px;
}

.p-objects__choosen-filters-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;
    margin-bottom: -5px;
}

.p-objects__choosen-filters-item {
    position: relative;
    margin-right: 25px;
    margin-bottom: 5px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    color: #BDBDBD;
    padding-right: 20px;

    br {
        display: none;
    }
}

.p-objects__choosen-filters-item-del-btn {
    position: absolute;
    display: block;
    content: "";
    top: 45%;
    right: 0;
    width: 10px;
    height: 10px;
    transform: translateY(-50%);

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 125%;
        height: 2px;
        background: #BDBDBD;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    @include linksHover();
}

.p-objects__filter-wrap {
    position: absolute;
    top: 183px;
    right: 50px;
    z-index: 1;
}

.p-objects__filter-toggle-btn {
    position: relative;
    padding-right: 17px;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: #BDBDBD;

    @include linksHover();

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid;
        transition: all .3s;
    }

    &.active {
        &:before {
            transform: translateY(-50%) rotate(180deg);
        }
    }
}

.p-objects__filter-dropdown {
    display: none;
    width: 280px;
    background: #040C44;
    position: absolute;
    top: 25px;
    right: 0;
}

.p-objects__filter-list {
    padding: 50px 65px 50px 75px;
    max-height: 430px;
    overflow: auto;
}

.p-objects__filter-item {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-objects__filter-btn {
    position: relative;
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: #fff;
    transition: all .3s;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: -1px;
        bottom: 1px;
        left: 0;
        width: 3px;
        background: #DF1616;
        opacity: 0;
        transition: all .3s;
    }

    &:hover {
        color: #DF1616;
    }

    &.active {
        color: #DF1616;
        padding-left: 13px;

        &:before {
            opacity: 1;
        }
    }
}

@media (max-width: 1279px) {
    .p-objects__bottom-filter {
        .p-objects__filter-toggle-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            overflow: hidden;
            height: 60px;
            width: 100%;
            font-size: 16px;
            line-height: 16px;
            color: #fff;
            background: $c-main;
            transition: all .3s;
            opacity: 1;

            &:before {
                position: relative;
                margin-left: 6px;
                flex: 0 0 auto;
                order: 2;
                transform: none;
                top: auto;
            }

            &:hover,
            &:active {
                opacity: 1;
                background: lighten($c-main, 5%);
            }

            &.active {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }

        .p-objects__filter-dropdown {
            right: auto;
            top: auto;
            bottom: 66px;
            left: 0;
        }
    }
}
 
@media (max-width: 767px) {
    .p-objects__bottom-filter {
        flex: 1;
        width: auto;

        .p-objects__filter-dropdown {
            right: 0;
            left: auto;
        }
    }
}