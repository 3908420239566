.p-contacts__info {
    margin-top: 80px;
    position: relative;
    padding-left: 30px;
    border-left: 4px solid #001480;
}

.p-contacts__info-item {
    margin-bottom: 32px;
    min-height: 52px;
    display: flex;
    color: $c-titles;

    &:last-child {
        min-height: 1px;
        margin-bottom: 0;
    }
}

.p-contacts__info-item-title {
    flex: 0 0 auto;
    width: 190px;
    padding-right: 10px;
    opacity: 0.7;
    font-size: 18px;
    line-height: 22px;
    font-family: $f-Roboto;   
}

.p-contacts__info-item-desc {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;

    p {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.p-contacts-tab-page {
    display: none;

    &.active {
        display: block;
    }
}

.p-contacts__map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media (max-width: 1599px) {
    .p-contacts__info-item-title {
        width: 130px;
    }
}

@media (max-width: 1279px) {
    .p-contacts__map {
        min-height: 500px;
        height: auto;
        flex: 1;
        position: relative;
    }

    .p-contacts.inner-page {
        flex-direction: column;
        min-height: 100vh;
    
        .inner-page__content {
            padding: 95px 24px 40px 24px;
        }

        .inner-page-r {
            display: flex;
        }
    }
}

@media (max-width: 767px) {
    .p-contacts.inner-page {
        .inner-page__content {
            padding: 105px 20px 55px;
        }
    }

    .p-contacts__info {
        padding-left: 0;
        border: none;
    }

    .p-contacts__info-item {
        min-height: 1px;
        margin-bottom: 40px;
    }

    .p-contacts__info-item-title {
        font-size: 16px;
        line-height: 19px;
        width: 105px;
    }

    .p-contacts__info-item-desc {
        font-size: 16px;
        line-height: 19px;
        padding-top: 2px;
    }

    .p-contacts__map {
        min-height: 300px;
    }
}