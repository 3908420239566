.clients {
    margin-bottom: 120px;
}

.clients-c {
    display: flex;
}

.clients-l {
    margin-right: 105px;
    flex: 0 0 auto;
}

.clients-r {
    flex: 1;
    min-width: 0;
    position: relative;
    border-left: 4px solid transparent;
    padding-left: 55px;
    mix-blend-mode: multiply;

    &:before {
        position: absolute;
        display: block;
        content: "";
        left: -4px;
        top: 0;
        width: 4px;
        height: 85px;
        background: #DF1616;
    }
}

.clients__title {
    font-size: 50px;
    line-height: 65px;
    font-weight: bold;
    color: $c-titles;
    width: 482px;
    max-width: 100%;
}

.clients-slider__nav {
    margin-top: 35px;
    display: flex;
    align-items: center;
}

.clients-slider__nav-c {
    display: flex;
    align-items: center;
}

.clients-slider__bar {
    flex: 1;
    min-width: 0;
    margin-right: 45px;
}

.clients-slider__item {
    width: auto;
    height: auto; 
    display: flex;
    align-items: center;
}

.clients-slider__item-img {
    filter: grayscale(1);
    transition: all .3s;

    &:hover {
        filter: grayscale(0);
    }
}

.clients-slider__item-img-container {
    position: relative;
} 

@media (max-width: 1279px) {
    .clients {
        margin-bottom: 90px;
    }

    .clients-c {
        flex-wrap: wrap;
    }

    .clients-l {
        margin-right: 0;
        margin-bottom: 25px;
        flex: 0 0 auto;
        width: 100%;
    }

    .clients-r {
        padding-left: 40px;
    }

    .clients__title {
        width: auto;
    }
}

@media (max-width: 767px) {
    .clients {
        margin-bottom: 60px;
    }

    .clients__title {
        font-size: 35px;
        line-height: 45px;
    }

    .clients-r {
        padding-left: 20px;
    }

    .clients-slider__bar {
        margin-right: 3px;
    }
}