.modal-objects.modal {
    max-width: 100%;
    padding: 0;
    background: #fff;
    min-height: 100%;

    a.close-modal {
        top: 30px;
        right: 25px;

        &:before,
        &:after {
            background: #0863EB;
        }
    }

    .p-objects__content {
        padding: 115px 170px 50px 125px;
    }

    .p-objects__filter-wrap {
        top: 123px;
        right: 170px;
    }
}

@media (max-width: 767px) {
    .modal-design.modal a.close-modal {
        top: 20px;
        right: 20px;
    }

    .modal-objects.modal {
        .p-objects__content {
            padding: 60px 20px 30px;
        }
    
        .p-objects__filter-wrap {
            top: 170px;
            right: auto;
            left: 20px;
        }

        .p-objects__filter-dropdown {
            left: 0;
            right: auto;
        }

        .inner-page__title {
            margin-bottom: 60px;
        }

        .p-objects__choosen-filters-list-c {
            margin-bottom: 25px;
        }
    }
}