.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100px;
    background: #FFFFFF;
    box-shadow: 5px 0px 50px rgba(0, 0, 0, 0.02);
    z-index: 100;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;

    &:before {
        position: absolute;
        display: block;
        content: "";
        z-index: 5;
        top: 0;
        right: 0;
        bottom: 0;
        background: #E0E0E0;
        width: 1px;
        pointer-events: none;
    }
}

.sidebar__logo {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.sidebar__logo-img {
    flex: 0 0 auto;
}

.sidebar-nav {
    margin-top: auto;
    padding-top: 12px;
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: 14px;
        right: 14px;
        height: 1px;
        background: #E0E0E0;
    }

    + .sidebar-nav {
        margin-top: 20px;
    }

    &_page-nav {
        &:before {
            display: none;
        }
    }
}

.sidebar-nav__item {
    position: relative;

    &:hover {
        .sidebar-nav__item-btn {
            background: #F2F2F2;
        }

        .sidebar-nav__item-inner {
            transform: translateX(0);
        }
    }

    &.active {
        pointer-events: none;

        .sidebar-nav__item-btn {
            background: #F2F2F2;

            &_bg-dark-blue {
                background: #001480;

                .sidebar-nav__item-btn-desc,
                .sidebar-nav__item-btn-img {
                    color: #fff;
                }

                .sidebar-nav__item-btn-img-part-fill-gray {
                    fill: #fff;
                }

                .sidebar-nav__item-btn-img-part-stroke-white {
                    stroke: #001480;
                }
            }

            &_bg-orange {
                background: #DF7616;

                .sidebar-nav__item-btn-desc,
                .sidebar-nav__item-btn-img {
                    color: #fff;
                }
            }
        }
    }
}

.sidebar-nav__item-btn {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    transition: all .3s;
    background: #fff;
    z-index: 1;
}

.sidebar-nav__item-btn-img-container {
    margin-bottom: 7px;
}

.sidebar-nav__item-btn-img {
    color: #333;

    &-part-fill-gray {
        fill: #333;
    }

    &-part-stroke-white {
        stroke: #fff;
    }
}

.sidebar-nav__item-btn-desc {
    text-align: center;
    color: #4F4F4F;
    font-size: 12px;
    line-height: 14px;
    font-family: $f-Roboto;
    font-weight: 300;
    transition: all .3s;
}

.sidebar-nav__item-btn-title {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    color: #4F4F4F;
    margin-bottom: 6px;
    transition: all .3s;

    &_text-underline {
        text-decoration: underline;
    }
}

.sidebar-nav__item-inner {
    position: absolute;
    left: 100%;
    top: 0;
    padding: 0 55px;
    background: #F2F2F2;
    transition: all .3s;
    transform: translateX(-105%);

    .sidebar-nav__item-btn {
        background: #F2F2F2;
        white-space: nowrap;

        &:hover {
            .sidebar-nav__item-btn-title {
                color: #333333;
            } 

            .sidebar-nav__item-btn-desc {
                color: #333333;
            }
        }
    }
}

.sidebar-nav__item-inner-list {
    display: flex;
}

.sidebar-nav__item-inner-item {
    margin-right: 40px;

    &:last-child {
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .sidebar {
        display: none;
    }

    .sidebar-nav__item-inner-item {
        margin-right: 25px;
    }
}