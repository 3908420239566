.p-products__tab {
    position: relative;
    border-left: 4px solid #DF1616;
    padding-left: 30px;
}

.p-products__description {
    font-family: $f-Roboto;
    font-size: 18px;
    line-height: 27px;

    p {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.p-products__items-zoom-imgs {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    &.active {
        + .p-products__slider-c {
            opacity: 0;
        }
    }
}

.p-products__items-zoom-img {
    pointer-events: all;
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1279px) {
    .p-products__items-zoom-imgs {
        display: none;

        &.active {
            + .p-products__slider-c {
                opacity: 1;
            }
        }
    }
}

@media (max-width: 767px) {
    .p-products__description {
        font-size: 16px;
        line-height: 24px;
    }
}