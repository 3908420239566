.inner-page {
    display: flex;
    height: 100vh;
}

.inner-page-l {
    position: relative;
    flex: 0 0 auto;
    z-index: 2;
    overflow: hidden;
    width: 40%;
    box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.04);
}

.inner-page-r {
    position: relative;
    flex: 1;
    min-width: 0;
    overflow: hidden;
}

.inner-page__content {
    position: relative;
    background: #fff;
    padding: 175px 90px 50px 50px;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.inner-page__tag {
    font-size: 24px;
    line-height: 31px;
    font-weight: bold;
    text-transform: uppercase;
    color: #BDBDBD;
    margin-bottom: 20px;
}

.inner-page__title {
    font-size: 50px;
    line-height: 65px;
    font-weight: bold;
    color: $c-main;
    margin-bottom: 30px;
}

.inner-page__nav {
    &_red {
        .tabs__nav-list {
            border-color: #E0E0E0;
        }
    
        .tabs__nav-btn {
            color: #BDBDBD;
            
            &:before {
                width: 57px;
                max-width: 100%;
                background: #E43535;
            }
    
            &.active,
            &:hover {
                color: #E43535;
            }
        }
    }

    &_dark-blue {
        .tabs__nav-list {
            border-color: #E0E0E0;
        }
    
        .tabs__nav-btn {
            color: #BDBDBD;
            
            &:before {
                width: 57px;
                max-width: 100%;
                background: #001480;
            }
    
            &.active,
            &:hover {
                color: #001480;
            }
        }
    }
}

.inner-page__close-btn-c {
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    padding-right: 25px;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    pointer-events: none;
}

.inner-page__close-btn { 
    position: relative;
    width: 26px;
    height: 26px;
    pointer-events: all;

    @include linksHover();

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 125%;
        height: 3px;
        background: #D83333;
        transform: translate(-50%, -50%) rotate(45deg);
        top: 50%;
        left: 50%;
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &_blue {
        &:before,
        &:after {
            background: #4E8FEF;
        }
    }
}

.tab-page-loader {
    position: fixed;
    left: 100px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
    transform: scaleX(0);
    transition: all 0.55s;
    transform-origin: left center;

    &_bg-dark-blue {
        background: #001480;
    }

    &_bg-orange {
        background: #DF7616;
    }

    &.active {
        transform: scaleX(1);
    }
}

@media (max-width: 1399px) {
    .inner-page__content {
        padding-left: 24px;
        padding-right: 35px;
    }
}

@media (max-width: 1279px) {
    .inner-page {
        height: auto;
        flex-direction: column-reverse;
    }

    .inner-page-l {
        width: 100%;
        box-shadow: none;
    }

    .inner-page__content {
        padding: 95px 160px 130px 24px;
    }

    .inner-page__close-btn-c {
        top: 45px;
        padding-right: 45px;
    }
}

@media (max-width: 991px) {
    .inner-page__content {
        padding-right: 24px;
    }
}

@media (max-width: 767px) {
    .inner-page__content {
        padding: 65px 20px 55px;
    }

    .inner-page__close-btn-c {
        top: 30px;
        padding-right: 30px;
    }

    .inner-page__close-btn {
        width: 22px;
        height: 22px;
    }

    .inner-page__tag {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    .inner-page__title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }

    .inner-page__nav {
        .tabs__nav-item {
            margin-right: 40px;
        }
    }

    .tab-page-loader {
        left: 0;
    }
}