.h-hero {
    position: relative;
    height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all .3s;
    
    > .container-fluid {
        display: flex;
        position: relative;
        z-index: 2;
    }

    &:before {
        position: absolute;
        display: block;
        content: "";
        bottom: 0;
        left: 0;
        right: 0;
        height: 35%;
        background: linear-gradient(0deg, rgba(2, 6, 27, 0.5) 0%, rgba(3, 7, 35, 0) 94.13%);
        pointer-events: none;
        z-index: 1;
    }

    &:after {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        height: 22%;
        background: linear-gradient(to bottom, rgba(2, 6, 27, 0.5) -66.74%, rgba(3, 7, 35, 0) 83.49%);
        pointer-events: none;
        opacity: 0.5;
        z-index: 1;
    }

    @media (max-width: 1200px){
        background-position: center right 65%;
    }

    @media (max-width: 767px){
        background-position: top right 35%;
    }
}

.h-hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, rgba(0, 14, 63, 0.8) 0%, rgba(0, 30, 138, 0.4) 100%);
        z-index: 1;
    }

    @media (max-width: 767px){
        left: 0;
    }
}

.h-hero__bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.h-hero__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 70px;
}

.h-hero__logo-container {
    margin-bottom: 60px;
}

.h-hero__desc {
    font-weight: 500;
    font-size: 25px;
    font-family: $f-Roboto;
    line-height: 35px;
    color: #fff;
    font-style: italic;
    max-width: 820px;
}

@media (max-width: 767px){
    .h-hero__inner {
        padding-bottom: 40px;
    }

    .h-hero__logo-container {
        margin-bottom: 25px;
        width: 240px;
    }

    .h-hero__desc {
        font-size: 16px;
        line-height: 22px;
    }
}