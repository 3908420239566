.modal-sertificate.modal {
    max-width: 100%;
    min-height: 100%;
    padding: 0;
}

.modal-sertificate-c {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.modal-sertificate-l {
    flex: 0 0 auto;
    width: 790px;
    padding-top: 215px;
}

.modal-sertificate-r {
    flex: 1;
    min-width: 0;
    padding: 0 0 0 30px;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    &::-webkit-scrollbar {
        display: none;
    }
}

.modal-sertificate__title {
    min-height: 210px;
    background: #E0E0E0;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 20px 60px 20px 200px;
    font-size: 50px;
    line-height: 65px;
    font-weight: bold;
    color: $c-titles;
}

.modal-sertificate__big-img-wrap {
    flex: 1;
    width: 510px;
    max-width: 510px;
    position: relative;
    padding-top: 215px;
}

.modal-sertificate__small-imgs-list {
    flex: 0 0 auto;
    width: 565px;
    padding-top: 215px;
    overflow: auto;
    padding: 215px 230px 50px 95px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.modal-sertificate__small-img,
.modal-sertificate__big-img {
    width: 100%;
    height: auto;
}

.modal-sertificate__big-img {
    opacity: 0; 
    transition: all .3s;

    &.active {
        opacity: 1;
    }
}

.modal-sertificate__small-img-wrap {
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        bottom: 0;
        left: -8px;
        width: 8px;
        background: #0863EB;
        opacity: 0;
        transform: translateX(-100%);
        transition: all .2s;
    }

    &:hover {
        .modal-sertificate__small-img-ico {
            transform: scale(1.2);
        }
    }

    &.active {
        pointer-events: none;

        &:before {
            opacity: 1;
            transform: none;
        }
    }
}

.modal-sertificate__small-img-ico {
    position: absolute;
    right: 17px;
    bottom: 14px;
    color: #404040;
    display: block;
    transform: scale(1);
    transition: all .3s;
}

@media (max-width: 1799px) {
    .modal-sertificate-l {
        width: 700px;
    }
}

@media (max-width: 1699px) {
    .modal-sertificate__small-imgs-list {
        width: 405px;
        padding: 215px 110px 50px 50px;
    }
}

@media (max-width: 1499px) {
    .modal-sertificate-l {
        width: 500px;
    }

    .modal-sertificate__small-imgs-list {
        width: 320px;
        padding: 215px 110px 50px 50px;
    }

    .modal-sertificate__title {
        padding: 20px 60px 20px 30px;
    }
}

@media (max-width: 1279px) {
    .modal-sertificate-c {
        flex-direction: column;
        height: auto;
    }

    .modal-sertificate-l {
        width: 705px;
        padding-top: 160px;
    }

    .modal-sertificate__title {
        padding: 20px 55px 20px 125px;
    }

    .modal-sertificate-r {
        width: 100%;
        flex: 0 0 auto;
        padding: 40px 20px 50px 20px;
    }

    .modal-sertificate__big-img-wrap,
    .modal-sertificate__small-img-ico {
        display: none;
    }

    .modal-sertificate__small-imgs-list {
        padding: 0;
        width: 100%;
        max-width: 580px;
        overflow: visible;
    }

    .modal-sertificate__small-img-wrap {
        pointer-events: none;
        cursor: default;

        &:before {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .modal-sertificate-l {
        width: 100%;
        padding-top: 70px;
    }

    .modal-sertificate-r {
        padding: 40px 30px 30px 30px;
    }

    .modal-sertificate__title {
        padding: 20px;
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        justify-content: center;
        min-height: 1px;
    }
}