.swiper-c {
    position: relative;
}

.swiper-controls {
    position: relative;
}

.swiper-custom-button-prev,
.swiper-custom-button-next {
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
    border-radius: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000;
    transition: all .3s;

    &:hover {
        background: #E0E0E0;
    }

    &.swiper-button-disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}

.swiper-custom-button-prev {
    pointer-events: all;

    svg {
        margin-left: -3px;
    }
}

.swiper-custom-button-next {
    pointer-events: all;

    svg {
        margin-left: 3px;
    }
}

.swiper-scrollbar {
    border-radius: 0;
    position: relative;
    height: 3px;
    background: #E0E0E0;
    transition-duration: 0ms;
}

.swiper-scrollbar-drag {
    background: #120080;
    border-radius: 0;
    cursor: pointer;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: -5px;
        bottom: -5px;
        left: 0;
        right: 0;
    }
}