.footer {
    background: #F5F5F5;
    padding: 50px 0 22px;
}

.footer-c {
    display: flex;
}

.footer-l {
    .footer__copy,
    .footer__sublinks-list,
    .footer__info-cols {
        display: none;
    }
}

.footer-m {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer-r {
    text-align: right;

    .footer__copy,
    .footer__sublinks-list {
        display: none;
    }
}

.footer__logo-img,
.footer__logo {
    display: block;
}

.footer__copy {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
}

.footer__sublinks-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__sublinks-item {
    margin-right: 12px;
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: "|";
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 5px;
        font-size: 14px;
        line-height: 20px;
        color: #828282;
    }

    &:last-child {
        margin-right: 0;
        
        &:before {
            display: none;
        }
    }
}


.footer__sublinks-link {
    @include linksHover();

    font-size: 14px;
    line-height: 20px;
    color: #828282;
}

.footer__sertificate {
    display: flex;
    align-items: center;
}

.footer__sertificate-l {
    flex: 0 0 auto;
    margin-right: 25px;
}

.footer__sertificate-r {
    flex: 0 0 auto;
}

.footer__sertificate-title {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 7px;
    color: #333333;
    text-transform: uppercase;
    font-weight: bold;
}

.footer__sertificate-link {
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline;
    color: #828282;
    display: block;
    
    &:hover {
        text-decoration: none;
    }
}

.footer__info-cols {
    display: flex;
    margin-bottom: 15px;
    padding-top: 14px;
}

.footer__info-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    &-1 {
        margin-right: 90px;
    }

    &-2 {
        margin-right: 60px;
    }
}

.footer__info-item {
    position: relative;
    max-width: 320px;
    padding-left: 35px;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    margin-bottom: 15px;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 22px;
        height: 1px;
        background: #BDBDBD;
    }
}

@media (max-width: 1599px) {
    .footer__info-col-1,
    .footer__info-col-2 {
        margin-right: 30px;
    }

    .footer__info-item {
        max-width: 230px;
    }
}

@media (max-width: 1399px) {
    .footer {
        padding: 45px 0 35px;
    }

    .footer-l {
        padding-top: 10px;

        .footer__copy,
        .footer__sublinks-list {
            display: block;
        }
    }

    .footer-m {
        padding-top: 10px;

        .footer__copy,
        .footer__sublinks-list {
            display: none;
        } 
    }

    .footer__sublinks-item {
        margin-right: 0;
        margin-bottom: 5px;

        &:before {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .footer__logo {
        margin-bottom: 20px;
    }

    .footer__copy {
        text-align: left;
        margin-bottom: 13px;
    }

    .footer__info-cols {
        padding-top: 0;
        margin-bottom: 0;
        flex-direction: column;
    }

    .footer__info-col-1, 
    .footer__info-col-2 {
        margin-right: 0;
    }

    .footer__info-item {
        max-width: 310px;
    }
}

@media (max-width: 1279px) {
    .footer__logo {
        margin-bottom: 20px;
        max-width: 200px;
    }

    .footer__copy {
        text-align: left;
    }

    .footer__sublinks-list {
        flex-direction: column;
        justify-content: center;
    }

    .footer__sublinks-item {
        margin-right: 0;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    .footer__sublinks-link {
        display: block;
    }

    .footer-m {
        display: none;
    }

    .footer-l {
        .footer__copy,
        .footer__sublinks-list {
            display: none;
        }

        .footer__info-cols {
            display: block;
        }
    }

    .footer-r {
        margin-left: auto;

        .footer__copy,
        .footer__sublinks-list {
            display: block;
            text-align: right;
        }
    }

    .footer__logo {
        margin-bottom: 50px;
    }
    
    .footer__sertificate {
        margin-bottom: 32px;
    }

    .footer__sublinks-list {
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .footer {
        padding: 60px 0 10px;
    }

    .footer-c {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-l {
        padding-top: 0;
        margin-bottom: 35px;
    }

    .footer-m,
    .footer__sublinks-list {
        align-items: flex-start;
    }

    .footer-m {
        margin-bottom: 40px;
    }

    .footer-r {
        text-align: left;
        margin-left: 0;
        width: 100%;

        .footer__copy, 
        .footer__sublinks-list {
            text-align: left;
        }
    }

    .footer__logo {
        max-width: 100%;
    }

    .footer__sertificate {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 50px;
    }

    .footer__sertificate-l {
        margin-right: 0;
    }

    .footer__sertificate-r {
        margin-right: 20px;
    }
}