.modal-request__subcontrols {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 45px;
}

.modal-request__subcontrols-title {
    padding-right: 15px;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: rgba($c-subtitles, 0.4);
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.modal-request__subcontrols-list {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.modal-request__subcontrols-item {
    flex: 0 0 auto;
    margin-right: 30px;

    &:last-child {
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .modal-request__subcontrols {
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-request__subcontrols-title {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .modal-request__subcontrols-list {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
    }

    .modal-request__subcontrols-item {
        margin-right: 0;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}