@mixin linksHover() {
	transition: all .3s ease-in-out;
	opacity: 1;

	&:hover {
		opacity: 0.6;
	}

	&:active {
		opacity: 1;
	}
}

@mixin states {
	@content;

	&:hover, &:active, &:focus {
		@content;
	}
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin getLetterS($spaceValueInPH, $fontSizeInPH) {
	letter-spacing: $spaceValueInPH * $fontSizeInPH / 1000 + px;
}

@mixin getSprite($sprite, $scale: 1) {
	$sprite-image: nth($sprite, 9);
	background-image: url(#{$sprite-image});

	$sprite-offset-x: nth($sprite, 3) * $scale;
	$sprite-offset-y: nth($sprite, 4) * $scale;
	background-position: $sprite-offset-x  $sprite-offset-y;

	$sprite-bs-x: nth($sprite, 7) * $scale;
	$sprite-bs-y: nth($sprite, 8) * $scale;
	background-size: $sprite-bs-x $sprite-bs-y;

	width: nth($sprite, 5) * $scale;
	height: nth($sprite, 6) * $scale;
}

@mixin breakpoint($point) {
	@if $point == xl-only {
		@media only screen and (min-width : 1200px) { @content ; }
	}

	@else if $point == lg {
		@media only screen and (max-width : 1199px) { @content ; }
	}

	@else if $point == lg-only {
		@media only screen and (max-width : 1199px) and (min-width: 992px) { @content ; }
	}

	@else if $point == md {
		@media only screen and (max-width : 991px) { @content ; }
	}

	@else if $point == md-only {
		@media only screen and (max-width : 991px) and (min-width: 768px) { @content ; }
	}

	@else if $point == sm {
		@media only screen and (max-width : 767px)  { @content ; }
	}

	@else if $point == sm-only {
		@media only screen and (max-width : 767px) and (min-width: 576px) { @content ; }
	}

	@else if $point == xs {
		@media only screen and (max-width : 575px)  { @content ; }
	}
}

%_arrow {
	position: absolute;
	display: inline-block;
	
	width: 20px;
	height: 20px;
	border: 1px solid red;

	border-top: none;
	border-right: none;
	
	&.top { transform: rotate(-135deg); }

	&.right { transform: rotate(-135deg); }

	&.bottom { transform: rotate(-45deg); }

	&.left { transform: rotate(45deg); }
}

%_triangle {
	position: absolute;
	display: inline-block;
	width: 0; 
	height: 0; 

	&.top {
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid black;
	}

	&.right {
		border-top: 60px solid transparent;
		border-bottom: 60px solid transparent;
		border-left: 60px solid black;
	}

	&.bottom {
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-top: 20px solid black;
	}

	&.left {
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent; 
		border-right: 10px solid black; 
	}
}