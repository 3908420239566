.p-products__features-item {
    display: flex;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-products__features-item-l {
    flex: 0 0 auto;
    width: 200px;
    margin-right: 20px;
}

.p-products__features-item-r {
    flex: 1;
    min-width: 0;
}

.p-products__features-item-img-container {
    position: relative;
}

.p-products__features-item-img {
    width: 100%;
    height: auto;
    display: block;
}

.p-products__features-item-tag {
    font-size: 16px;
    line-height: 19px;
    color: #BDBDBD;
    text-transform: uppercase;
    font-family: $f-Roboto;
    font-weight: bold;
    margin-bottom: 10px;
}

.p-products__features-item-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    font-family: $f-Roboto;
    color: #040C44;
    margin-bottom: 10px;
}

.p-products__features-item-desc {
    font-size: 16px;
    line-height: 24px;
    font-family: $f-Roboto;
    color: #333333;
}

@media (max-width: 1599px) {
    .p-products__features-item {
        flex-direction: column;
    }

    .p-products__features-item-l {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .p-products__features-item-r {
        flex: 0 0 auto;
    }
}