.p-company-tab-page {
    display: none;
    width: 100%;

    &.active {
        display: flex;
    }
}

.p-company__close-btn {
    display: none;
}

@media (max-width: 1279px) {
    .p-company {
        flex-direction: column;
        min-height: 100vh;
    }

    .p-company-tab-page {
        flex: 1;
        height: auto;
        
        &.active {
            flex-direction: column;
        }
    }
}