.company-team-content {
    padding-bottom: calc(100vh / 2);
}

.p-company__team-full-imgs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.p-company__team-full-img {
    pointer-events: all;
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.active {
        display: block;
    }
}

.p-company__team-list {
    margin-top: 80px;
}

.p-company__team-item {
    border-left: 4px solid #DF7616;
    padding-left: 30px;
    margin-bottom: 100px;
    transition: all .3s;
    opacity: 0.3;

    &.active {
        opacity: 1;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.p-company__team-item-l {
    display: none;
}

.p-company__team-item-title {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 35px;
    font-weight: bold;
    color: #040C44;
}

.p-company__team-item-info {
    font-family: $f-Roboto;
    font-size: 20px;
    line-height: 32px;
    color: #818181;
    margin-bottom: 30px;

    p {
        margin-bottom: 0;
    }
}

.p-company__team-item-desc {
    font-family: $f-Roboto;
    font-style: italic;
    font-size: 20px;
    line-height: 32px;
    color: #818181;
}

@media (max-width: 1279px) {
    .company-team-content {
        padding-bottom: 50px;
    }
    
    .p-company__team-item {
        display: flex;
        margin-bottom: 50px;
        opacity: 1;
    }

    .p-company__team-item-l {
        display: block;
        flex: 0 0 auto;
        width: 320px;
        margin-right: 50px;
    }

    .p-company__team-item-r {
        flex: 1;
        min-width: 0;
    }

    .p-company__team-item-img-container {
        height: 340px;
        width: 100%;
        overflow: hidden;
    }

    .p-company__team-item-img {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .p-company__team-item-l {
        width: 225px;
        margin-right: 30px;
    }
}


@media (max-width: 767px) {
    .company-team-content {
        padding-top: 105px;
    }

    .p-company__team-list {
        margin-top: 40px;
    }

    .p-company__team-item {
        border: none;
        padding-left: 0;
        flex-direction: column;
    }

    .p-company__team-item-img-container {
        width: 100%;
        height: 70vw;
        border-bottom: 9px solid #DF7616;
    }

    .p-company__team-item-l {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .p-company__team-item-title {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 20px;
    }

    .p-company__team-item-info {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 10px;

        p {
            margin-bottom: 3px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .p-company__team-item-desc {
        font-size: 16px;
        line-height: 25px;
    }
}