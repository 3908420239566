.modal-elevator.modal {
    max-width: 100%;
}

.modal-elevator__inner {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
}

.modal-elevator__bot-btns {
    margin-top: 120px;
}

@media (max-width: 991px) {
    .modal-elevator__bot-btns {
        margin-top: 40px;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 767px) {
    .modal-elevator__bot-btns {
        max-width: 590px;

        > .container-fluid {
            padding: 0;
        }
    }
}