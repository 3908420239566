.p-objects__products-list {
    position: relative;
    border-left: 4px solid #0863EB;
    padding-left: 45px;
}

.p-objects__products-item {
    display: none;
    margin-bottom: 15px;
    font-family: $f-Roboto;

    &.active {
        display: block;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.object-card {
    display: flex;

    &:hover {
        .object-card__img-container {
            &:before {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}

.object-card-l {
    flex: 0 0 auto;
    width: 123px;
    margin-right: 30px;
}

.object-card-r {
    flex: 1;
    min-width: 0;
}

.object-card__img-container {
    padding-top: 100%;
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: -10px;
        bottom: 0;
        width: 10px;
        background: #0863EB;
        z-index: 1;
        transition: all .3s;
        transform: translateX(-100%);
        opacity: 0;
    }
}

.object-card__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: initial;
    object-fit: cover;
}

.object-card__title {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    color: $c-titles;
}

.object-card__info-item {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 19px;

    &:last-child {
        margin-bottom: 0;
    }
}

.object-card__info-item-title {
    color: #BDBDBD;
}

.object-card__info-item-desc {
    color: $c-titles;
}

.p-objects__bottom-controls {
    display: none;
}

@media (max-width: 1279px) {
    .p-objects-l {
        display: none;
    }

    .p-objects__bottom-controls {
        display: block;
        position: absolute;
        z-index: 5;
        bottom: 45px;
        left: 25px;
    }

    .p-objects__bottom-controls-btn-projects {
        width: 135px;
    }

    .p-objects__bottom-controls-btn {
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 767px) {
    .p-objects__bottom-controls {
        left: 20px;
        right: 20px;
        bottom: 14px;
        display: flex;
    }

    .p-objects__bottom-controls-btn {
        flex: 1;
        margin-bottom: 0;
        margin-right: 6px;
        width: auto;
        padding: 0;
    }

    .p-objects__products-list {
        padding-left: 18px;
    }

    .object-card {
        flex-direction: column;

        &:hover {
            .object-card__img-container {
                &:before {
                    transform: translateY(0);
                }
            }
        }
    }

    .object-card-l {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .object-card__img-container {
        padding-top: 49%;

        &:before {
            left: 0;
            right: 0;
            top: auto;
            bottom: 0;
            width: auto;
            height: 10px;
            transform: translateY(100%);
        }
    }

    .p-objects__products-item {
        margin-bottom: 30px;
    }
}