.p-products__slider-c {
    opacity: 1;
    transition: all .3s;
}

.p-products__slider-btn {
    position: absolute;
    width: 75px;
    height: 75px;
    background: #fff;

    &-prev,
    &-next {
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
    }

    &-prev {
        left: 0;
    }

    &-next {
        right: 0;
    }

    svg {
        width: 9px;
        height: 20px;
    }
}

.p-products-slider__item-img-container {
    height: 100vh;
    position: relative;
}

.p-products-slider__item-img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 767px) {
    .p-products-slider__item-img-container {
        height: 63vh;
    }

    .p-products__slider-btn {
        top: auto;
        bottom: 0;
        transform: none;
        width: 50px;
        height: 50px;

        svg {
            width: 7px;
            height: 14px;
        }
    }
}