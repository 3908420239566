.fields-rows {
    margin-bottom: 30px;
}

.fields-row {
    margin-bottom: 13px;

    &:last-child {
        margin-bottom: 0;
    }
}

.fields-wrap {
    position: relative;
}

.fields-label {
    display: block;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    padding: 0 25px;
    margin-bottom: 7px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $c-subtitles;
    opacity: 0.4;
}

.input-default {
    display: block;
    height: 65px;
    border: 1px solid transparent;
    outline: none;
    padding: 0 25px;
    background: #FFFFFF;
    border-radius: 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    width: 100%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    transition: all .3s;
    color: $c-subtitles;

    &::placeholder {
        color: rgba($c-subtitles, 0.1);
    }

    &:focus {
        border-color: $c-main;
    }

    &.input_error {
        border-color: #DC0000 !important;
    }
}



.radio-label {
    display: block;
}

.radio-input {
    display: none;
}

.radio-input {
    &:checked + .radio-text {
        &:after {
            transform: scale(1);
            opacity: 1;
        }
    }     
}

.radio-text {
    display: block;
    padding-left: 38px;
    min-height: 28px;
    position: relative;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $c-subtitles;
    text-align: left;

    &:before {
        position: absolute;
        display: block;
        content: "";
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid $c-subtitles;
        left: 0;
        top: 0;
    }

    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 28px;
        height: 28px;
        left: 0;
        top: 0;
        border-radius: 50%;
        background: $c-subtitles;
        padding: 4px;
        background-clip: content-box;
        transform: scale(0);
        opacity: 0;
        transition: all .3s;
    }
}



.custom-select-wrap {
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: "";
        pointer-events: none;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black;
        transform: translateY(-50%) rotate(180deg);
        right: 25px;
        top: 50%;
    }

    .custom-select {
        padding-right: 45px;
    }
}

.custom-select {
    display: block;
    height: 65px;
    border: 1px solid transparent;
    outline: none;
    padding: 0 25px;
    background: #FFFFFF;
    border-radius: 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    width: 100%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    transition: all .3s;
    color: $c-subtitles;
    appearance: none;
    cursor: pointer;

    &.input_error {
        border-color: #DC0000 !important;
    }

    &:invalid {
        color: rgba($c-subtitles, 0.1);
    }

    option {
        color: $c-subtitles;

        &[value=""][disabled] {
            display: none;
        }
    }
}



.file-input-c {
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.file-input-l {
    flex: 0 0 auto;
    padding-right: 15px;

    @media (max-width: 767px) {
        padding-right: 0;
        margin-bottom: 15px;
        width: 100%;
    }
}

.file-input-r {
    flex: 1;
    min-width: 0;
    text-align: right;

    @media (max-width: 767px) {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        text-align: left;
    }
}

.file-input-label {
    display: block;
    @include linksHover();
}

.file-input-field-ico {
    margin-right: 10px;
    margin-top: -1px;
    color: #00083D;
}

.file-input-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: rgba($c-subtitles, 0.6);
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.file-input-choosen-item {
    display: none;
    position: relative;
    padding-right: 23px;
    
    &.active {
        display: block;
    }
}

.file-input-choosen-item-title {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $c-subtitles;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-input-choosen-item-delete {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 13px;
    height: 13px;
    margin-top: -1px;

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 125%;
        height: 2px;
        background: $c-subtitles;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    @include linksHover();
}