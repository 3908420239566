.fw-thin {
	font-weight: 100 !important;
}

.fw-exlight {
	font-weight: 200 !important;
}

.fw-light {
	font-weight: 300 !important;
}

.fw-reg {
	font-weight: normal !important;
}

.fw-med {
	font-weight: 500 !important;
}

.fw-semi {
	font-weight: 600 !important;
}

.fw-bold {
	font-weight: bold !important;
}

.fw-extrbld {
	font-weight: 800 !important;
}

.fw-black {
	font-weight: 900 !important;
}

.text-line-through {
	text-decoration: line-through !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-block {
	p {
		font-family: $f-Roboto;
		font-size: 18px;
		line-height: 29px;
		color: #333333;
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: 767px) {
			font-size: 16px;
			line-height: 26px;
		}
	}
}