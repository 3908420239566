.company-clients-content {
    width: 100%;
}

.p-company__clients-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
    border-left: 4px solid #DF7616;
    padding-left: 60px;
    margin-top: 80px;
}

.p-company__clients-item {
    flex: 0 0 auto;
    width: 25%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-company__clients-item-img {
    max-width: 100%;
    height: auto;
    flex: 0 0 auto;
    filter: grayscale(1);
    transition: all .3s;

    &:hover {
        filter: grayscale(0);
    }
}

@media (max-width: 1499px) {
    .p-company__clients-item {
        width: 33.33333333333333333%;
    }
}

@media (max-width: 1279px) {
    .company-clients-content {
        padding-right: 24px;
        flex: 1;
    }

    .p-company__clients-list {
        margin-top: 60px;
        padding-left: 30px;
    }

    .p-company__clients-item {
        width: 50%;
    }
}


@media (max-width: 767px) {
    .company-clients-content {
        padding-top: 105px;
    }

    .p-company__clients-list {
        margin-top: 40px;
        padding-left: 5px;
    }

    .p-company__clients-item {
        width: 100%;
        margin-bottom: 15px;
    }

    .p-company__clients-item-img {
        padding: 0 30px;
    }
}