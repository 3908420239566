.contacts {
    overflow: hidden;
}

.contacts-c {
    display: flex;
    margin: 0 -50px;
    min-height: 705px;
}

.contacts-l {
    width: 730px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 140px 90px 50px 50px;
    color: #fff;
    background: $c-main;
}

.contacts-r {
    background: gray;
    flex: 1;
    min-width: 0;
}

.contacts__title {
    font-size: 50px;
    line-height: 65px;
    font-weight: bold;
    margin-bottom: 40px;
}

.contacts__info-item {
    margin-bottom: 40px;
    display: flex;
    align-items: baseline;

    &:last-child {
        margin-bottom: 0;
    }
}

.contacts__info-item-title {
    flex: 0 0 auto;
    width: 190px;
    padding-right: 10px;
    opacity: 0.7;
    font-size: 18px;
    line-height: 22px;
    font-family: $f-Roboto;
}

.contacts__info-item-desc {
    font-size: 18px;
    line-height: 22px;

    p {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.contacts-map-c { 
    height: 100%;
    overflow: hidden;
}

[class$=-ground-pane] {
    filter: grayscale(1);
}

@media (max-width: 1279px) {
    .contacts-c {
        margin-right: -25px;
        margin-left: -25px;
        min-height: 710px;
    }

    .contacts-l {
        width: 50%;
        padding: 140px 25px 50px;
    }

    .contacts__info-item-title {
        width: 130px;
    }
}

@media (max-width: 991px) {
    .contacts-c {
        flex-wrap: wrap;
        min-height: auto;
    }

    .contacts-l {
        width: 100%;
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .contacts-r {
        flex: 0 0 auto;
        width: 100%;
        height: 500px;
    }
}

@media (max-width: 767px) {
    .contacts-c {
        margin-left: -20px;
        margin-right: -20px;
    }

    .contacts-l {
        padding: 65px 20px;
    }

    .contacts-r {
        height: 300px;
    }

    .contacts__title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }

    .contacts__info-item-title {
        font-size: 16px;
        line-height: 19px;
        width: 105px;
    }
    
    .contacts__info-item-desc {
        font-size: 16px;
        line-height: 19px;
    }
}