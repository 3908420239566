.custom-tab {
    position: relative;
    display: none;

    &.active {
        display: block;
    }
}

.tabs-nav {
    position: relative;
}

.tabs__nav-list-c {
    margin-bottom: 60px;
    overflow: auto;
    white-space: nowrap;
    display: flex;

    &::-webkit-scrollbar {
        display: none;
    }

    &.dragged {
        cursor: grabbing;
    }
}

.tabs__nav-list {
    display: flex;
    position: relative;
    flex: 1;
    border-bottom: 2px solid rgba(#fff, 0.2);
}

.tabs__nav-item {
    margin-right: 50px;
    
    &:last-child {
        margin-right: 0;
    }
}

.tabs__nav-btn {
    margin-bottom: -2px;
    position: relative;
    font-size: 16px;
    line-height: 19px;
    color: rgba(#fff, 0.4);
    padding: 0 0 12px;
    transition: color .3s;

    &:before {
        position: absolute;
        display: block;
        content: "";
        transition: all .3s;
        bottom: 0;
        left: 0;
        width: 48px;
        height: 5px;
        background: #FFFFFF;
        opacity: 0;
    }

    &.active,
    &:hover {
        color: #fff;

        &:before {
            opacity: 1;
        }
    }
}

@media (max-width: 767px) {
    .tabs__nav-list-c {
        display: flex;
        white-space: nowrap;
        overflow: auto;
        margin-left: -20px;
        padding-left: 20px;
        margin-right: -20px;
        padding-right: 20px;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}