.clr-btn {
	@extend %clear-btn;
}

.more-btn-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
}

.more-btn {
	display: flex;
	align-items: center;
	color: $c-subtitles;
	font-size: 16px;
	line-height: 16px;
	font-weight: bold;
	transition: all .3s;

	&:hover,
	&:active {
		background: lighten($c-main, 5%);
	}

	&-ico {
		margin-left: 20px;
		transition: all .3s;
	}
}

.btn-def {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	overflow: hidden;
	height: 60px;
	width: 100%;
	font-size: 16px;
	line-height: 16px;
	color: #fff;
	background: $c-main;
	transition: all .3s;
	
	&:hover,
	&:active {
		background: lighten($c-main, 5%);
	}

	&-ico {
		margin-right: 25px;
		flex: 0 0 auto;

		@media (max-width: 767px){
			margin-right: 20px;
		}
	}
}