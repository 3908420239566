.p-company__description {
    margin-top: 80px;
    padding-left: 30px;
    border-left: 4px solid #DF7616;
    font-family: $f-Roboto;
    font-size: 20px;
    line-height: 32px;
    color: #818181;

    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.p-company__slogan-img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1279px) {
    .p-company__slogan-img {
        position: relative;
        height: 680px;
    }
    
    .p-company-tab-page.p-company-tab-3 {
        flex-direction: column-reverse;
    }
}

@media (max-width: 767px) {
    .p-company__slogan-img {
        height: 63vh;
    }

    .p-company__description {
        margin-top: 35px;
        font-size: 16px;
        line-height: 24px;
    }
}