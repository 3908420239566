.modal-policy.modal {
    max-width: 100%;
    min-height: 100%;
    padding: 0;
}

.modal-policy-c {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.modal-policy-l {
    flex: 0 0 auto;
    width: 790px;
    padding-top: 215px;
}

.modal-policy-r {
    flex: 1;
    min-width: 0;
    padding: 235px 170px 50px 80px;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.modal-policy__title {
    min-height: 210px;
    background: #E0E0E0;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 20px 60px 20px 200px;
    font-size: 50px;
    line-height: 65px;
    font-weight: bold;
    color: $c-titles;
}

.modal-policy__desc {
    margin-bottom: 50px;
}

.modal-policy__info-item {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.modal-policy__info-item-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-family: $f-Roboto;
}

.modal-policy__info-item-title-num {
    margin-left: -22px;
    margin-right: 10px;
    height: 44px;
    min-width: 44px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #DC0000;
    color: #fff;
    padding: 5px;
    font-weight: 900;
    font-size: 18px;
}

.modal-policy__info-item-title-text {
    color: $c-titles;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    flex: 1;
    min-width: 0;
}

@media (max-width: 1599px) {
    .modal-policy-l {
        width: 600px;
    }

    .modal-policy-r {
        padding: 235px 110px 50px 70px;
    }

    .modal-policy__title {
        padding: 20px 60px 20px 30px;
    }
}

@media (max-width: 1279px) {
    .modal-policy-c {
        flex-direction: column;
        height: auto;
    }

    .modal-policy-l {
        width: 705px;
        padding-top: 160px;
    }

    .modal-policy__title {
        padding: 20px 55px 20px 125px;
    }

    .modal-policy-r {
        width: 100%;
        flex: 0 0 auto;
        padding: 40px 20px 50px 145px;
    }
}

@media (max-width: 767px) {
    .modal-policy-l {
        width: 100%;
        padding-top: 70px;
    }

    .modal-policy-r {
        padding: 40px 20px 30px 30px;
    }

    .modal-policy__title {
        padding: 20px;
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        justify-content: center;
        min-height: 1px;
    }

    .modal-policy__desc {
        margin-bottom: 25px;
    }

    .modal-policy__info-item-title-num {
        margin-left: -10px;
    }

    .modal-policy__info-item-title-text {
        font-size: 16px;
        line-height: 26px;
    }
}