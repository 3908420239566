.p-object__content {
    margin-top: 80px;
}

.p-object__content-item {
    position: relative;
    border-left: 4px solid #4E8FEF;
    padding-left: 30px;
    margin-bottom: 60px;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-object__info {
    padding: 14px 0;
}

.p-object__info-item {
    display: flex;
    font-family: $f-Roboto;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 17px;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-object__info-item-title {
    flex: 0 0 auto;
    width: 140px;
    margin-right: 50px;
    color: #828282;
}

.p-object__info-item-desc {
    flex: 1;
    min-width: 0;
    color: $c-titles;
    font-weight: bold;
}

@media (max-width: 767px) {
    .p-object__content {
        margin-top: 40px;
    }

    .p-object__content-item {
        margin-bottom: 45px;
    }

    .p-object__info-item {
        font-size: 16px;
    }

    .p-object__info-item-title {
        width: 120px;
        margin-right: 10px;
    }
}