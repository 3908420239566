$f-Roboto: 'Roboto', sans-serif;
$f-Muller: 'Muller', sans-serif;

$c-main     : #00083D;
$c-titles   : #00083D;
$c-subtitles: #030723;
$c-texts    : #333333;

%clear-btn {
	@include states {
		outline: none;
	}
	border: none;
	background: none;
	cursor: pointer;
	padding: 0;
}