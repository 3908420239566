/* ROBOTO */

/* roboto-100 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-100italic - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-100italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100italic.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-100italic.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-300 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-regular - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-300italic - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-300italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300italic.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-300italic.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-italic - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-500 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-700 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-500italic - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-700italic - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-700italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700italic.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-700italic.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-900 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-900.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-900italic - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/Roboto/roboto-v29-latin_cyrillic-900italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900italic.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Roboto/roboto-v29-latin_cyrillic-900italic.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* ROBOTO ... end; */





/* Muller */

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerUltraLight.eot');
    src: local(''),
        url('../fonts/Muller/MullerUltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBoldItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerMedium.eot');
    src: local(''),
        url('../fonts/Muller/MullerMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerExtraBoldItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerUltraLightItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerUltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerRegular.eot');
    src: local(''),

        url('../fonts/Muller/MullerRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBlackItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerHairlineItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerHairlineItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerHeavyItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerHeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerThinItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Muller Hairline';
    src: url('../fonts/Muller/MullerHairline.eot');
    src: local(''),
        url('../fonts/Muller/MullerHairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerHeavy.eot');
    src: local(''),
        url('../fonts/Muller/MullerHeavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerThin.eot');
    src: local(''),
        url('../fonts/Muller/MullerThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerLightItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerLight.eot');
    src: local(''),
        url('../fonts/Muller/MullerLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBlack.eot');
    src: local(''),
        url('../fonts/Muller/MullerBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerBold.eot');
    src: local(''),
        url('../fonts/Muller/MullerBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerExtraBold.eot');
    src: local(''),
        url('../fonts/Muller/MullerExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerRegularItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerRegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('../fonts/Muller/MullerMediumItalic.eot');
    src: local(''),
        url('../fonts/Muller/MullerMediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

/* Muller ... end;*/
