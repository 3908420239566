$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;

//mixins

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints)==null, "", "-#{$name}");
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min !=0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }

    @else {
        @content;
    }
}

// mixins.. end;

// visibilities loop

@mixin doVisibilities ($breakpoints: $grid-breakpoints){
    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $value in $displays {
                .d#{$infix}-#{$value} {
                    display: $value !important;
                }
            }
        }
    }
}

// visibilities loop .. end;
