.svg-icon-doc {
	height:26px;
	width:23px;
}
.svg-icon-file-input-ico {
	height:19px;
	width:19px;
}
.svg-icon-img-zoom-ico {
	height:24px;
	width:24px;
}
.svg-icon-inst-ico {
	height:18px;
	width:18px;
}
.svg-icon-mail {
	height:15px;
	width:20px;
}
.svg-icon-more-ico {
	height:20px;
	width:22px;
}
.svg-icon-partners {
	height:15px;
	width:20px;
}
.svg-icon-phone {
	height:15px;
	width:15px;
}
.svg-icon-rutube-ico {
	height:14px;
	width:19px;
}
.svg-icon-showreel {
	height:16px;
	width:21px;
}
.svg-icon-slider-arrow-left {
	height:11px;
	width:5px;
}
.svg-icon-slider-arrow-right {
	height:11px;
	width:5px;
}
.svg-icon-team {
	height:15px;
	width:12px;
}
.svg-icon-vk-ico {
	height:12px;
	width:21px;
}
.svg-icon-yin-yan {
	height:19px;
	width:20px;
}
.svg-icon-yt-ico {
	height:14px;
	width:19px;
}
