.svg-icon-doc-download-ico {
	height:18px;
	width:16px;
}
.svg-icon-doc-ico {
	height:18px;
	width:15px;
}
.svg-icon-map-pin {
	height:79px;
	width:59px;
}
.svg-icon-map-pin-2 {
	height:77px;
	width:55px;
}
.svg-icon-pdf-ico {
	height:18px;
	width:14px;
}
.svg-icon-social-net {
	height:18px;
	width:14px;
}
