/* Animations */
@keyframes preloader-inside-white {
    0%{ 
        transform: scale(0, 0);
    }
    100%{
        transform: scale(1, 1);
    }
}

@keyframes preloader-inside-red {
    0%{
        transform: scale(0, 0);
    }
    30%{
        transform: scale(0, 0);
    }
    100%{
        transform: scale(1, 1);
    }
}

@keyframes preloader-change-color {
    0% {
        background: #DF1616;
    }

    50%{
        background: #120080;
    }

    100% {
        background: #DF1616;
    }
}

/* Styles */
.preloader{
    display: inline-block;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background: white;
    text-align: center;
    overflow: hidden;
    transition: all .5s cubic-bezier(.8,.57,.4,.92) .3s;

    &.not-active {
        height: 0;

        .preloader-container {
            opacity: 0;
        }
    }

    .preloader-container{
        display: inline-block;
        width: 100px;
        height: 100px;
        margin: auto;
        position: absolute;
        top: 44%;
        left: 0;
        position: relative;
        transition: all .2s;
        opacity: 1;

        .animated-preloader{
            display: inline-block;
            width: 100px;
            height: 100px;
            position: absolute;
            top: 0;
            left: 0;
            background: #DF1616;
            border-radius: 50%;
            animation: preloader-change-color 2s both infinite;

            &:after{
                content: '';
                display: inline-block;
                width: 100px;
                height: 100px;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 50%;
                background: white;
                -webkit-animation: preloader-inside-white 1s ease-in-out infinite;
                -ms-animation:     preloader-inside-white 1s ease-in-out infinite;
                animation:         preloader-inside-white 1s ease-in-out infinite;
            }
            
            &:before{
                content: '';
                display: inline-block;
                width: 100px;
                height: 100px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                border-radius: 50%;
                background: #DF1616;
                -webkit-animation: preloader-inside-red 1s ease-in-out infinite;
                -ms-animation:     preloader-inside-red 1s ease-in-out infinite;
                animation:         preloader-inside-red 1s ease-in-out infinite, preloader-change-color 2s both infinite;
            }
        }
    }
}