.p-objects__map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.p-objects__map-controls {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 25px;
    transform: tranlsateY(-50%);
}

.p-objects__map-btn {
    display: block;
    position: relative;
    margin-bottom: 2px;
    width: 60px;
    height: 60px;
    background: $c-main;
    transition: all .3s;

    &:hover {
        background: lighten($c-main, 5%);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 15px;
        height: 2px;
        background: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.p-objects__map-minus-btn {
    &:after {
        display: none;
    }
}



.active-mark {
    z-index: 999999 !important;
}

.objects-map-placemark {
    width: 34px;
    min-height: 48px;
    position: absolute;
    left: -17px;
    top: -47px;
    transition: all .3s;
    transform-origin: bottom left;

    &.active {
        width: 385px;
        min-height: 144px;
        top: -140px;
        left: -48px;
        
        .objects-map-placemark__pin {
            width: 103px;
            height: 144px;
        }

        .objects-map-placemark__info {
            width: 100%;
            opacity: 1;
            transition-delay: .2s;
        }
    }
}

.objects-map-placemark__pin {
    width: 34px;
    height: 48px;
    transition: all .3s;
    position: absolute;
}

.objects-map-placemark__info {
    position: relative;
    z-index: 1;
    margin-top: 11px;
    margin-left: 25px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    width: 0;
    opacity: 0;
    transition: all .3s;
    transition-delay: 0s;
}

.objects-map-placemark__info-l {
    flex: 0 0 auto;
}

.objects-map-placemark__info-r {
    flex: 1;
    min-width: 0;
    background: #fff;
    padding: 16px 5px 15px 18px;
    align-self: stretch;
    display: flex;
    align-items: center;
}

.objects-map-placemark__info-img-container {
    position: relative;
    width: 113px;
    height: 76px;
}

.objects-map-placemark__info-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.objects-map-placemark__info-title {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    min-width: 250px;
    color: #040C44;
    text-transform: uppercase;
}

@media (max-width: 1279px) {
    .p-objects__map {
        position: relative;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
}

@media (max-width: 767px) {
    .p-objects__map-controls {
        left: 20px;
        top: auto;
        bottom: 102px;
        transform: none;
    }

    .p-objects__map-btn {
        width: 42px;
        height: 42px;

        &:before,
        &:after {
            width: 10px;
        }
    }

    .objects-map-placemark {
        &.active {
            width: 230px;
            min-height: 80px;
            top: -80px;
            left: -26px;
            
            .objects-map-placemark__pin {
                width: 57px;
                height: 80px; 
            }
        }
    }
    
    .objects-map-placemark__info {  
        margin-top: -7px;
        margin-left: 10px; 
    }

    .objects-map-placemark__info-r {
        background: #fff;
        padding: 11px 9px 30px 45px;
        align-self: flex-start;
        margin-left: -30px;
        max-width: 164px;
    }

    .objects-map-placemark__info-img-container {
        width: 85px;
        height: 58px;
    }

    .objects-map-placemark__info-title {
        font-size: 14px;
        line-height: 18px;
        min-width: 111px;
    }
}