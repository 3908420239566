.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
}

p {
	&:last-child {
		margin-bottom: 0;
	}
}

figure {
	margin: 0;
}

label {
	font-weight: normal;
	cursor: pointer;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
}

a,
a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}

img {
	max-width: 100%;
	height: auto;
}

html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	line-height: normal;
	font-family: $f-Muller;
	color: #000000;
	background: #F5F5F5;
	padding-left: 100px;

	&::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: 767px) {
		padding-left: 0;
	}
}

.main {
	flex: 1;
}

.footer {
	flex: 0 0 auto;
	margin-top: auto;
}

.svg-icon {
	display: inline-block;
	fill: currentColor;
}

.body-overflow {
	overflow: hidden;
}

.container-fluid {
	padding-left: 50px;
	padding-right: 50px;

	@media (max-width: 1279px) {
		padding-left: 25px;
		padding-right: 25px;
	}

	@media (max-width: 767px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}