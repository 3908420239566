.header {
    position: fixed;
    top: 0;
    left: 100px;
    right: 0;
    z-index: 95;

    &_point-styles {
        .header-nav__item {
            border-color: #E0E0E0;
        }

        .header-nav__link {
            background: #fff;
        }
    }
}

.header-nav__list {
    display: flex;
}

.header-nav__item {
    flex: 1;
    border-right: 1px solid transparent;
    transition: all .3s;

    &:last-child {
        border-right: transparent;
    }
}

.header-nav__link {
    width: 100%;
    height: 65px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: $c-subtitles;
    background: rgba(#FFFFFF, 0.7);
    transition: all .3s;

    span {
        position: relative;
        z-index: 1;
    }

    &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        height: 0;
        background: black;
        opacity: 0.7;
        transition: all .3s;
    }

    &:hover {
        color: #fff;
        background: transparent;

        &:before {
            height: 100vh;
        }
    }

    &.active {
        color: #fff;
        background: transparent;
        pointer-events: none;
        
        &:before {
            height: 100%;
        }
    }

    &_bg-red {
        &:before {
            background: rgb(206, 0, 0);
        }
    }

    &_bg-yellow {
        &:before {
            background: #DF7616; 
        }

        &.active:before {
            opacity: 1;
        }
    }

    &_bg-blue {
        &:before {
            background: rgb(8, 99, 235);
        }
    }

    &_bg-purple {
        &:before {
            background: rgb(0, 20, 128);
        }

        &.active:before {
            opacity: 1;
        }
    }

    &_bg-gray {
        &:before {
            background: rgb(255, 255, 255);
        }
    }

    &_c-black {
        &:hover {
            color: $c-subtitles;
        }
    }

    &_w-arrow {
        span {
            padding-right: 25px;

            &:after {
                position: absolute;
                display: block;
                content: "";
                width: 8px;
                height: 8px;
                border: 2px solid $c-subtitles;
                border-top: none;
                border-right: none;
                transform: translateY(-50%) rotate(-135deg);
                top: 50%;
                right: 0;
                margin-right: 2px;
                margin-top: -1px;
            }
        }
    }
}

@media (max-width: 1279px) {
    .header {
        height: 65px;
        display: flex;
        padding-right: 20px;
        align-items: center;
        transition: background .3s;

        &_point-styles {
            background: #F5F5F5;

            .header__menu-toggler {
                span {
                    background: #333;
                }
            }
        }

        &.header-style-red {
            .header__menu-toggler {
                span {
                    background: #D63131;
                }
            }
        }

        &.header-style-orange {
            .header__menu-toggler {
                span {
                    background: #DF7616;
                }
            }
        }

        &.header-style-blue {
            .header__menu-toggler {
                span {
                    background: #0863EB;
                }
            }

            .header__mob-logo-img {
                &-red {
                    fill: #E54040;
                }

                &-blue {
                    fill: #120080;
                }
            }
        }
    }

    .header-nav {
        display: none;
    }

    .header__menu-toggler {
        margin-left: auto;
        width: 48px;
        height: 26px;
        position: relative;

        span {
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            background: #fff;
            right: 0;
            transition: all .3s;

            &:nth-child(1){
                top: 0;
            }

            &:nth-child(2){
                top: 50%;
                margin-top: -1px;
                width: 70%;
            }

            &:nth-child(3){
                bottom: 0;
            }
        }

        &:before {
            position: absolute;
            display: block;
            content: "";
            top: -10px;
            bottom: -10px;
            left: -5px;
            right: -5px;
        }
    }
}

@media (max-width: 767px){
    .header {
        height: 77px;
        border-bottom: 1px solid #E0E0E0;
        left: 0;
        padding-left: 20px;

        &_point-styles {
            border-color: transparent;

            .header__mob-logo-img-blue {
                fill: #120080;
            }

            .header__mob-logo-img-red {
                fill: #DF1616;
            }
        }

        &.header-style-blue {
            border-bottom: none;
            pointer-events: none;

            > * {
                pointer-events: all;
            }
        }
    }

    .header__menu-toggler {
        width: 28px;
        height: 16px;

        span {
            height: 1px;
        }
    }

    .header__mob-logo-img {
        display: block;

        path {
            transition: all .3s;
        }
    }
}