// visibilities

$grid-breakpoints: (
    xs: 0,
    md: 768px,
    lg: 992px,
    xl: 1280px
) !default;

@include doVisibilities();

// visibilities ... end;

// container

.container-fluid {
	@include make-container();
}

// container ... end;