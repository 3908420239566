.menu-overlay {
	display: block;
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9500;
	visibility: visible;
	opacity: 0;
	pointer-events: none;
	transition: all .3s;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}
}

.mob-menu {
    transform: translateX(105%);
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9550;
    right: 0;
    top: 0;
    bottom: 0;
    width: 600px;
	max-width: 100%;
	background: #FFFFFF;
	box-shadow: 5px 0px 50px rgba(0, 0, 0, 0.02);
    transition: 0.5s;

    &.active {
        transform: translateX(0);
    }
}

.mob-menu-c {
    padding: 110px 0 75px;
    height: 100%;
    overflow-y: auto;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.mob-menu__close-btn {
    position: absolute;
    display: block;
    top: 30px;
    right: 20px;
	width: 26px;
	height: 26px;

	&:before,
	&:after {
		position: absolute;
		display: block;
		content: "";
		width: 125%;
		height: 2px;
		top: 50%;
		left: 50%;
		background: #333333;
		transform: translate(-50%, -50%) rotate(45deg);
		transition: all .3s;
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	&:hover {
		&:before,
		&:after {
			background: #DF1616;
		}
	}
}

.mob-menu__nav-item {
	position: relative;

	.sidebar-nav__list {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 0 30px;
	}

	.sidebar-nav__item {
		flex: 1;
	}

	.sidebar-nav__item-btn {
		width: 100%;
		height: 90px;

		&.active {
			background: #F2F2F2;
		}
	}
}

.mob-menu__nav-link {
	height: 122px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-align: right;
	padding: 5px 55px;
	font-size: 20px;
	line-height: 20px;
	font-weight: bold;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	position: relative;
	transition: all .3s;
	color: $c-subtitles;

	&:after {
		position: absolute;
		display: block;
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		width: 0;
		transition: all .3s;
	}

	span {
		position: relative;
		z-index: 1;
	}

	&:hover,
	&:active,
	&.active {
		color: #fff;

		&:after {
			width: 100%;
		}

		+ .mob-menu__nav-btn {
			&:before {
				border-color: #fff;
			}
		}
	}

	&_gray {
		background: #F2F2F2;
		color: $c-subtitles;

		&:hover {
			color: $c-subtitles;
			
			&:after {
				display: none;
			}
		}
	}

	&_w-arrow {
		&:before {
			position: absolute;
			display: block;
			content: "";
			width: 10px;
			height: 10px;
			border: 2px solid #000;
			border-right: none;
			border-top: none;
			top: 50%;
			left: 77px;
			transform: translateY(-50%) rotate(-135deg);
		}
	}

	&_bg-red {
		&:after {
			background: #ce0000;
		}
	}

	&_bg-blue {
		&:after {
			background: #0863eb;
		}
	}

	&_bg-dark-blue {
		&:after {
			background: #001480;
		}
	}

	&_bg-orange {
		&:after {
			background: #DF7616;
		}
	}
}

.mob-menu__nav-btn {
	position: absolute;
	top: 0;
	left: 0;
	height: 122px;
	width: 155px;

	&:before {
		position: absolute;
		display: block;
		content: "";
		width: 10px;
		height: 10px;
		border: 2px solid #000;
		border-right: none;
		border-top: none;
		top: 45%;
		left: 77px;
		transform: translateY(-50%) rotate(-45deg);
		transition: all .3s;
	} 

	&.active {
		&:before {
			top: 50%;
			transform: translateY(-50%) rotate(-225deg);
		}
	}
}

.mob-menu__bot {
	margin-top: auto;
	padding-top: 30px;
	padding: 30px 20px 0 65px;
	display: flex;
	justify-content: space-between;

	.footer__sublinks-link {
		text-align: right;
	}

	.footer__sublinks-list {
		align-items: flex-end;
		margin-bottom: 0;
	}

	.footer__sertificate {
		text-align: right;
		margin-bottom: 0;
	}
}

.mob-menu-nav-sublinks {
	display: none;

	&_2-cols {
		.sidebar-nav__list {
			flex-wrap: wrap;
		}

		.sidebar-nav__item {
			flex: 0 0 auto;
			width: 50%;
		}
	}
}

@media (max-width: 767px){
	.mob-menu {
		width: 100%;
	}
	
	.mob-menu-c {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	.mob-menu__bot {
		display: none;
	}

	.mob-menu__close-btn {
		width: 22px;
		height: 22px;
		top: 20px;
		right: 20px;
	}

	.mob-menu__nav-link {
		height: 90px;
		padding: 10px 20px 10px 55px;
		font-size: 16px;
		line-height: 16px;

		&_w-arrow {
			&:before {
				margin-top: -1px;
				left: 20px;
			}
		}
	}

	.mob-menu__nav-btn {
		height: 90px;
		width: 55px;

		&:before {	 
			left: 20px; 
		} 
	}

	.mob-menu__info {
		margin-top: 12px;

		.sidebar-nav__list {
			display: flex;
			justify-content: center;
			width: 100%;
		}

		.sidebar-nav__item {
			flex: 1;

			&:hover {
				.sidebar-nav__item-btn {
					background: #fff;

					&.active {
						background: #F2F2F2;
					}
				}
			}
		}

		.sidebar-nav__item-btn {
			width: 100%;
			height: 70px;

			&.active {
				background: #F2F2F2;
			}
		}

		.sidebar-nav__item-inner-item {
			.sidebar-nav__item-btn {
				width: auto;
				height: auto;
			}
		}

		.sidebar-nav__item-inner {
			position: relative;
			left: auto;
			top: auto;
			padding: 0;
			background: #F2F2F2;
			transform: none;
		}

		.sidebar-nav__inner-item {
			display: none;

			&.active {
				display: block;
			}
		}

		.sidebar-nav__item-inner-list {
			min-height: 90px;
			align-items: center;
			justify-content: center;
		}
	}
}